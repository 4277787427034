 <template>
<div>
  <b-navbar toggleable="lg" type="dark" variant="primary" sticky="true">
		<b-navbar-brand>{{ $route.meta.displayTitle }}</b-navbar-brand>

	<!--This automatically displays the menu as a pill menu on smaller devices -->
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="pt-2">
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
 
		<b-nav-item to="dashboard">Dashboard</b-nav-item>
		<b-nav-item to="reports">Entry Report</b-nav-item>
		<b-nav-item to="register">Add Entry</b-nav-item>
		<b-nav-item to="adminSettings">Settings</b-nav-item>
		<b-nav-item @click="logout">Log out</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
	
export default {
  name: 'topHeader',
	data: function () {
		return {
    
		}
	},
    methods :{
        logout(){
            this.$store.commit('logOut');
            window.localStorage.clear()
            this.$nextTick(()=>{
                window.location = ('/login')
            })
        }
    }
}
</script>