<template>
  <div class="container">
    <b-overlay :show="showOverlay">
      <top-header />
        <div class="flex-container">
            <div class="flex-item">
                <b-input-group prepend="Event Status:" class="my-1">
                <b-form-radio-group
                    v-model="eventStatus"
                    @change="dataChanged = true"
                    :options="eventStatusOptions"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    buttons
                ></b-form-radio-group>
                </b-input-group>               
            </div>            
        </div>
        <div class="flex-container">
            <div class="flex-item">
            <b-input-group prepend="Event Status Title:" class="my-1">
                <b-form-input v-model="eventStatusText"  @keyup="dataChanged = true"></b-form-input>
            </b-input-group>             
            </div>         
        </div>
        <div class="flex-container">
            <div class="flex-item">
                <b-input-group prepend="Event status text:" class="my-1">
                    <b-form-textarea v-model="eventStatusSubtext"
                        placeholder=""
                        maxlength="10000"
                        rows="6"
                        max-rows="6"                    
                        @keyup="dataChanged = true"
                    > </b-form-textarea>
                </b-input-group>             
            </div>            
        </div>
        <div class="flex-container my-4">
            <div class="flex-item">
                <b-button @click="updateEventDetails" class="ml-4" variant="success" v-if="dataChanged">Save Changes</b-button>
                <b-button @click="getEventDetails" class="ml-4" variant="danger" v-if="dataChanged">Undo changes</b-button>
            </div>            
        </div>
        <div id="statusJumbotron" class="">
            <h4>Event Closed Page Preview</h4>
            <eventStatusJumbotron :eventStatusText="eventStatusText" :eventStatusSubtext="eventStatusSubtext" />
        </div>
          <template #overlay>
        <custom-overlay :overlayText="overlayText"/> 
    </template> 
    </b-overlay>
    </div>
</template>
<script>
import CustomOverlay from '@/components/CustomOverlay'
import EventStatusJumbotron from './EventStatusJumbotron.vue'
import TopHeader from './topHeader.vue'
import {RepositoryFactory} from '../api/RepositoryFactory'
import {AdminMixin} from '@/mixins/AdminMixin'
const settingsPost = RepositoryFactory.post('settings')
const settingsGet = RepositoryFactory.get('settings')
export default {
name: "AdminSettings",
components: {CustomOverlay, EventStatusJumbotron,TopHeader},
mixins: [AdminMixin],
 data(){
      return {
        dataChanged: false,
        showOverlay : false,
        overlayText : "Saving data..",
        eventStatusText: null,
        eventStatusSubtext: null,
        eventStatus: null,
        eventStatusOptions : [
        { text : "Closed", value : "Closed"},
        { text : "Open", value : "Open"} 
        ],
      }
 },
 methods:{
    async getEventDetails()
    {
        await settingsGet.getEventDetails().then(response=>{
          console.log(response.data)
          const eventData = response.data
          console.log(eventData)
          this.eventStatus = eventData.filter(item => item.name == 'eventStatus')[0].value
          this.eventStatusText = eventData.filter(item => item.name == 'eventStatusText')[0].value
          this.eventStatusSubtext = eventData.filter(item => item.name == 'eventStatusSubtext')[0].value
          this.dataChanged = false
        })
    },
    async updateEventDetails()
    {  
        this.showOverlay = true
        this.overlayText = "Updating Event"

        let formData = new FormData()

        formData.append("appKey", "6A26879E-DEE9-4B81-B15C-BA6861E44532" )
        formData.append("eventStatus" , this.eventStatus)        
        formData.append("eventStatusText" , this.eventStatusText)        
        formData.append("eventStatusSubtext" , this.eventStatusSubtext)
        
        await settingsPost.updateEventDetails(formData).then(response=>{
          console.log(response)
          this.displayOverlay("Changes Saved")
          this.dataChanged = false
        })
    },
    displayOverlay(overlaytext){
          setTimeout(()=>{
          this.overlayText = overlaytext   
        setTimeout(()=>{
          this.showOverlay = false
          this.overlayText = null
        }, 3000)
      }, 3000)
    },
 },
 mounted(){
     this.getEventDetails() 
 }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.flex-container>div {
  width: 100%;
  margin: 10px;
  line-height: 75px;
  font-size: 30px;
}

.flex-item-left
{
  text-align: left;
}

.flex-item-right
{
  text-align: right;
}
</style>