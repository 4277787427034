<template>
    <div class="text-center">
        <b-icon icon="three-dots" animation="cylon" variant="success" font-scale="4"></b-icon>  
        <p>{{overlayText}}</p>                  
    </div>
</template>
<script>
export default{
    name : 'CustomOverlay',
    props: ['overlayText']
}
</script>
<style scoped>

</style>